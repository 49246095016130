.footer-container {
  font-family: Montserrat, sans-serif;
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  align-items: center;
  width: 100%;
}

.footer-subscription {
  font-family: Montserrat, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: Montserrat, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
  font-family: Montserrat, sans-serif;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
  background-color: #e9e9e9;
  font-family: Montserrat, sans-serif;
}

.btn--suscribe{
  background-color: #b1b1b1;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 5px;
}
.btn--suscribe:hover{
  background-color: gray;
 
}

.footer-links {
  width: 100%;
  /* max-width: 1000px; */
  display: flex;
  justify-content:space-around;
  padding: 0px;
}

.footer-link-wrapper {
  display: flex;
  /* background-color: white; */
  margin-left: 3rem;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items:center;
  margin: 1px;
  padding-left: 0rem;
  text-align:justify;
  width: 300px;
  box-sizing: border-box;
  line-height: 2;
  font-family: Montserrat, sans-serif;
  /* background-color: aqua; */

}

.footer-link-items h2 {
  margin-bottom: 12px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 18px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 240px; */
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 2rem;
  display: grid;
  justify-content: center;
  font-size: 1rem;
  font-family: Montserrat, sans-serif;
  /* background-color: #e9e9e9; */
}
.greenButton {
  background: #008000;
  color: #fff;
  width: initial;
  padding: 20px 20px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 4px;
  margin-left: 25px;
  
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  font-family: Montserrat, sans-serif;
  letter-spacing: 2px;
  word-spacing:0px;  
  
}
.greenButton:hover{
  background-color: #008000;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 600px) {
  .footer-links {
    width: 100%;
    /* max-width: 1000px; */
    display: flex;
    flex-direction: column;
    /* background-color: yellow; */
    padding: 0px;
  }
  
  .footer-link-wrapper {
    display: flex;
    width: 80%;
   /* background-color: red; */
    /* margin-left: 3rem; */
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    margin: 0px;
    /* padding-left: 2rem; */
    text-align:justify;
    /* width: 300px; */
    box-sizing: border-box;
    line-height: 2;
    font-family: Montserrat, sans-serif;
    /* background-color: blue; */
   
  
  }
  .greenButton {
    background: #008000;
    color: #fff;
    width: initial;
    padding: 20px 20px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 4px;
    margin-left: 25px;
    
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-family: Montserrat, sans-serif;
    letter-spacing: 2px;
    word-spacing:0px;  
    
  }
  .greenButton:hover{
    background-color: #008000;
  }

  }