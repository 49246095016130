
/* video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  } */
  
  .video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  } 
  /* .videoWrapper{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  } */
*{
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

  
  .hero-container {
    background: url("/public/images/bgbeach.png") center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
    object-fit: contain;
  }


  .responsive-car{
  width: 100%;
  max-width: 800px;
  height: auto;
  }

  .top-left {
    position: absolute;
    top: 150px;  
    left: 100px;
    color: black; 
    font-size: 1.1rem;
    font-weight: 80;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }

  .img-container{
    font-size: 3rem;
    font-family: 'Arial Narrow Bold', sans-serif;
  }

  .split {
    padding: 40px;
    background-color: black;
    color: #fff;
    font-size: .8rem;

    display: flex;
    justify-content: center;
    align-items: center;
   
  }

  .redButton {
    background: #c81d1f;
    color: #fff;
    width: initial;
    padding: 20px 20px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 4px;
    margin-left: 25px;
    
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-family: Montserrat, sans-serif;
    letter-spacing: 2px;
    word-spacing:0px;  
    
  }
  .redButton:hover{
    background-color: red;
  }

  .responsive {
    height: auto;
    width: 100%;
    float: left;

  }
  .image-militar-container{
    position: relative;
    
    float: left;

  }

  .botton-left{
    position: absolute;
    bottom: 60px;
    left: 16px;
    color: #fff;
    background-color: #c81d1f;
    
    color: #fff;
    width: initial;
    padding: 12px 20px;
    font-size: .9rem;
    font-weight: 700;
    letter-spacing: 4px;
    margin-left: 25px;
    border-radius: 4px;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-family: Montserrat, sans-serif;
    letter-spacing: 2px;
    word-spacing:0px;  
  
  }
  .botton-left:hover{
    background-color: red;
  }
  
  .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }


    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
      letter-spacing: 20px;
    }
  }
  
  @media screen and (max-width: 768px) {

    .hero-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        }

    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
     
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
  
