*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: sans-serif;

}
.container{
  display: grid; 
}

.container-image{ 
  width: 100%;
  
}



.image1{
  /* position: relative; */
  top: 0px;
  left: 0px;
  width: 100;
}
.image2 {
  position: absolute;
  top: 0px;
  left: 0px;
  /* border: 2px green solid; */
  width: 100%;
}

.container-title{ 
  font-family: Montserrat, sans-serif;
  /* letter-spacing: 2px; */
  /* word-spacing:0px; */
  font-size: .8rem;
  /* margin-right: 200px; */
  font-weight:700;
  margin-bottom: 10px;
}


.border-colors{
  /* position: relative; */
  border: .7px solid gray;
  
  padding: 20px 10px 10px 10px;
  margin-left: 10px;
 
  margin-bottom: 20px;
  margin-top: 250px;
  width: 96%;
}


.border-rims {
  border: .7px solid gray;
  padding: 20px 10px 10px 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 96%;
}


.border-seats  {
  border: .7px solid gray;
  padding: 20px 10px 10px 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 96%;
}

.border-top  {
  border: .7px solid gray;
  padding: 20px 10px 10px 10px;
  margin-left: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 96%;
}

.container-moke{
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  /* margin: 0px 20px 20px 20px; */
}

.container-moke .card-moke {
  /* background-color: purple; */
  width: 125px;
  padding: 2px; 
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: Montserrat, sans-serif;
  letter-spacing: 2px;
  word-spacing:0px;  
}


.container-moke .card-moke p {
  margin: 5px;
  font-weight: 750;
  font-size: .6rem;
  /* background-color: aqua; */
  font-family: Montserrat, sans-serif;

  letter-spacing: 2px;
  word-spacing:0px; 
  /* background-color: aqua; */
}

.container-moke .card-moke .box-moke {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 50%;
  cursor: pointer;
  border: .1px solid black;
  padding: 0px 10px 0 10px 0px;
  /* justify-content: center; */
}
.bg-gray{
  background: lightgray;
}

.pagination-container{
  padding: 30px;
  align-self: center;
  /* background-color: blue; */
  text-align: center;
}

a {
  text-decoration: none;
  display: inline-block;
  padding: 20px 40px;
}

a:hover {
  background-color: #ddd;
  color: black;
}

.previous {
  background-color: black;
  color: white;
  font-weight: 750;
  font-size: 1rem;
  /* background-color: aqua; */
  font-family: Montserrat, sans-serif;

  letter-spacing: 2px;
  word-spacing:0px; 
}

.next {
  background-color: black;
  color: white;
  font-weight: 750;
  font-size: 1rem;
  /* background-color: aqua; */
  font-family: Montserrat, sans-serif;

  letter-spacing: 2px;
  word-spacing:0px; 
}

.btn-group {
  font-family: Montserrat, sans-serif;
  /* position: relative; */
  /* display: -ms-inline-flexbox; */
  display: inline-flex;
  /* vertical-align: middle; */
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}
.border-dark {
  border-color: #343a40!important;
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn  {
  /* display: inline-block; */
  /* font-family: Montserrat, sans-serif; */
  font-weight: 400;
  color: #212529;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: .7rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button, select {
  text-transform:uppercase;
}



.btn-add-cart{
  background-color: black;
  color: white;
  padding: 1rem 1rem 1rem 1rem;
  margin: 25px;
  cursor: pointer;
  justify-content: center;
  align-self: center;
  text-align: center;
  font-size: 1.5rem;
  font-family: Montserrat, sans-serif;
}
.btn-add-cart:hover{
  background-color: rgb(67, 67, 67);
 
}