.hero-container-rent {
    /* background: url("/public/videos/rentmoke.mp4") center center/cover no-repeat; */

   margin-top: 100px;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
    object-fit: contain;
  }