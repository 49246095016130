* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.shop,
.specialeditions,
.about,
.faq,
.specs,
.news,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.shop {
  /* background-image: url('../public/images/1.1.png'); */
  display: flex;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}



.specialeditions {

  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}
.about {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.faq {

  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}
.specs {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.news {

  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.image-slider{
  font-family: Montserrat, sans-serif;
  /* background-color: #242424; */
  padding: 1rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  align-items: center;
  width: 100%;
}