

.default-car-container {
    position: relative;
    
    top: 0px;
    left: 10px;
    /* background-color: blue; */   
  }
  .image1{
    /* position: relative; */
    top: 0px;
    left: 0px;
    width: 100;
  }
  .image2 {
    position: absolute;
    top: 0px;
    left: 0px;
    /* border: 2px green solid; */
    width: 100%;
    max-width: 600px;
  }
  @media screen and (min-width: 550px) {
    .container-image { 
      width: 600px;
      height: 100px;
    
    
  
    }
  
    
  }